import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    pxToRem,
    DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV,
} from '@archipro-design/aria';

import { svgIconClassName } from '@archipro-design/icons';
import { OverrideGoogleMap } from './OverrideGoogleMap.style';

const MAP_WIDTH = 896;
const MAP_HEIGHT = 700;
const LEFT_LIST_WIDTH = 800;
export const SCROLLBAR_WIDTH = 6;
export const SCROLLBAR_LEFT_GAP = 18;
export * from './LocationListDetail.style';
export * from './LocationListItem.style';

interface LocationListItemProps {
    isClicked?: boolean;
    isHovered?: boolean;
}

export const ContactContainer: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        paddingTop: pxToRem(76),
        paddingBottom: pxToRem(81),
    },
});

export const LeftListSection: StyleRule = () => ({
    width: pxToRem(LEFT_LIST_WIDTH),
    // width: pxToRem(LEFT_LIST_WIDTH + SCROLLBAR_LEFT_GAP + SCROLLBAR_WIDTH),
    // height: pxToRem(MAP_HEIGHT),
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
});

export const RightMapSection: StyleRule = ({ theme }) => ({
    width: pxToRem(MAP_WIDTH),
    height: pxToRem(MAP_HEIGHT),
    marginLeft: pxToRem(SCROLLBAR_LEFT_GAP),
    textAlign: 'center',
    border: `2px solid ${theme.siteVariables.colors.grey[125]}`,

    ...OverrideGoogleMap(theme),

    [theme.screen.laptop]: {
        position: 'sticky',
        top: pxToRem(DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV),
    },
});

export const LeftListActions: StyleRule = () => ({
    paddingBottom: pxToRem(14),
});

export const LineDivider: StyleRule = ({ theme }) => ({
    border: `1px solid ${theme.siteVariables.colors.grey[125]}`,
    margin: 0,
    width: pxToRem(LEFT_LIST_WIDTH - 2),
});

export const SearchBarSection: StyleRule = () => ({
    margin: 0,
});

export const SearchBarInput: StyleRule = ({ theme }) => ({
    '& input': {
        fontSize: pxToRem(18),
        border: 0,
        textAlign: 'left',
        letterSpacing: theme.siteVariables.letterSpacingNormal,
    },

    [`& .${svgIconClassName}`]: {
        marginTop: pxToRem(-1),

        [`& path:last-child`]: {
            fill: theme.siteVariables.colorScheme.default?.foreground,
        },
    },
});

export const LocationListSection: StyleRule = ({ theme }) => ({
    height: '100%',
    width: '100%',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.siteVariables.colors.gold['000']} #E8E9EB`,
    overscrollBehavior: 'contain',

    '&::-webkit-scrollbar': {
        width: pxToRem(SCROLLBAR_WIDTH),
    },

    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.siteVariables.colors.white[300],
        borderRadius: pxToRem(30),
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.siteVariables.colors.gold['000'],
        borderRadius: pxToRem(30),
    },
});

export const LocationListWrapper: StyleRule = () => ({
    width: pxToRem(LEFT_LIST_WIDTH),
    height: '100%',
});

export const NoSearchResult: StyleRule = ({ theme }) => ({
    paddingTop: 0,

    [`& > .${captionClassName}`]: {
        lineHeight: pxToRem(24),
    },

    [theme.screen.laptop]: {
        width: pxToRem(LEFT_LIST_WIDTH),
        paddingTop: pxToRem(6),
    },
});

export const MapMarkerIconDiv: StyleRule<LocationListItemProps> = ({
    theme,
    isClicked,
    isHovered,
}) => {
    const PIN_WIDTH = 40;
    const PIN_HEIGHT = 40;
    return {
        position: 'relative',
        top: pxToRem(-PIN_HEIGHT), // make the marker bottom-center point to the coordinate
        left: pxToRem(-(PIN_WIDTH / 2)),
        height: pxToRem(PIN_HEIGHT),
        width: pxToRem(PIN_WIDTH),
        cursor: 'pointer',

        '> span': {
            height: pxToRem(PIN_HEIGHT),
            width: pxToRem(PIN_WIDTH),
        },

        // when location or map-pin is clicked or hovered, change the map-pin z-index
        zIndex: isClicked
            ? theme.siteVariables.zIndexes.overlay
            : isHovered
            ? theme.siteVariables.zIndexes.foreground
            : theme.siteVariables.zIndexes.background,
    };
};

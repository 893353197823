import {
    Button,
    Caption,
    Flex,
    Grid,
    Label,
    pxToRem,
    useStyles,
} from '@archipro-design/aria';
import { CloseLine } from '@archipro-design/icons';
import { useNavigate, useOutletContext } from '@remix-run/react';
import type {
    ContactLoaderData,
    LocationDataType,
} from '@modules/professional/type/contact-page-types';
import {
    getFullAddress,
    getLocationProfessional,
} from '@modules/professional/util/contact-page-helper';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import { useSaveToDesignBoard } from '~/modules/design-board/hook/use-save-to-design-board';
import * as S from './LocationMapPopup.style';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import ClickToView from '~/modules/root/component/click-to-view/ClickToView';
import type { ProfessionalOutletContext } from '~/modules/professional/page/ProfessionalPage';

interface LocationMapPopupProps {
    clickedLocation: LocationDataType;
    onMapPopupClose?: () => void;
    linkedProfiles: ContactLoaderData['linkedProfiles'];
}

export const LocationMapPopup = ({
    clickedLocation: location,
    onMapPopupClose,
    linkedProfiles,
}: LocationMapPopupProps) => {
    const { css } = useStyles({ hasOnlineOrder: location.AllowsPickup });
    const navigate = useNavigate();
    const initialData = useProfessionalInitialRouteData();

    const onViewProfile = (
        e: React.SyntheticEvent<HTMLElement, Event>,
        routePath: string
    ) => {
        e.preventDefault();
        navigate(routePath);
    };

    const locationProfessional = getLocationProfessional(
        location,
        initialData,
        linkedProfiles
    );

    const { favouriteItem } = useSaveToDesignBoard('ProfessionalPin');
    const { openEnquiryModal } = useOutletContext<ProfessionalOutletContext>();
    return (
        <Grid columns={1} className={css(S.MapPopupWrapper)}>
            <Flex space="between" hAlign="end" className={css(S.LocationType)}>
                <div>{location.Type}</div>

                <Button
                    onClick={onMapPopupClose}
                    transparent={true}
                    icon={<CloseLine size="2x" />}
                    size={30}
                    variables={{
                        buttonPadding: 4,
                    }}
                />
            </Flex>

            <div className={css(S.LocationTitle)}>
                <Caption variant="03">{location.Title}</Caption>
            </div>

            <Flex column className={css(S.LocationAddress)}>
                <ClickToView
                    clickLabel="Address"
                    fulltext={getFullAddress(location)}
                    coordinates={location.MapCoordinates}
                    type={'Location'}
                    label={initialData.Title}
                    buttonTrackingID={
                        locationProfessional.PageType.toLowerCase() +
                        '-contact-accordion-address-reveal'
                    }
                    revealKey={`professional-${locationProfessional.ID}`}
                />
            </Flex>
            <Flex column className={css(S.LocationPhone)}>
                <ClickToView
                    clickLabel="Phone"
                    fulltext={location.Phone || ''}
                    type={'Phone'}
                    label={initialData.Title}
                    buttonTrackingID={
                        locationProfessional.PageType.toLowerCase() +
                        '-library-location-phone-reveal'
                    }
                    revealKey={`professional-${locationProfessional.ID}`}
                />
            </Flex>

            {location.AllowsPickup && (
                <Flex column className={css(S.ClickAndCollectAvailable)}>
                    <Label variant="02">Click & collect available</Label>
                </Flex>
            )}

            <Grid
                columns={`1fr ${pxToRem(48)} 1fr`}
                className={css(S.EnquiryAndSaveWrapper)}
            >
                <div>
                    <Button
                        color="dark"
                        size={24}
                        className={css(S.EnquireButton)}
                        variables={{
                            darkButtonBackgroundColorNormal: '#222222',
                        }}
                        onClick={() => openEnquiryModal()}
                    >
                        Enquire
                    </Button>
                </div>
                <SaveToDesignBoard
                    {...favouriteItem}
                    itemId={locationProfessional?.ID}
                    isFavourited={favouriteItem.favourited(
                        locationProfessional?.ID
                    )}
                    heartButton={{
                        size: 16,
                        heartSize: '1x',
                        className: css(S.SaveToDesignBoardButton),
                    }}
                />

                <Flex hAlign="center">
                    <Button
                        as={'a'}
                        href={`/professional/${locationProfessional.URLSegment}`}
                        color="dark"
                        transparent={true}
                        size={16}
                        variables={{
                            buttonPadding: 0,
                        }}
                        className={css(S.ViewProfileButton)}
                        onClick={(e) =>
                            onViewProfile(
                                e,
                                `/professional/${locationProfessional.URLSegment}`
                            )
                        }
                    >
                        View Profile
                    </Button>
                </Flex>
            </Grid>
        </Grid>
    );
};

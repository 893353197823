import type { FC } from 'react';
import * as S from './ContactPage.desktop.style';

import {
    MapMarkerDefault,
    MapMarkerHovered,
    MapMarkerSelected,
} from '@archipro-design/icons';
import { useStyles, useAppDisplayModeContext } from '@archipro-design/aria';
import type { LocationDataType } from '@modules/professional/type/contact-page-types';
import { LocationMapPopup } from '@modules/professional/component/contact-tab/LocationMapPopup';
import type { ContactLoaderData } from '@modules/professional/type/contact-page-types';

export interface LocationMapMarkerProps {
    lat?: number;
    lng?: number;
    $hover?: boolean;
    locationID: number;
    hoveredLocation?: LocationDataType;
    clickedLocation?: LocationDataType;
    onMapPopupClose?: () => void;
    linkedProfiles?: ContactLoaderData['linkedProfiles'];
}

const LocationMapMarker: FC<LocationMapMarkerProps> = ({
    $hover, // this is a built-in prop provided from the "google-map-react" library
    hoveredLocation,
    clickedLocation,
    locationID,
    onMapPopupClose,
    linkedProfiles,
}) => {
    const isClicked = clickedLocation?.ID === locationID;
    const isHovered = hoveredLocation?.ID === locationID || $hover;
    const { css } = useStyles({ isHovered, isClicked });
    const { mobile: isMobile } = useAppDisplayModeContext();

    return (
        <div className={css(S.MapMarkerIconDiv)}>
            {isClicked ? (
                <>
                    <MapMarkerSelected size="2x" />
                    {isMobile && linkedProfiles && (
                        <LocationMapPopup
                            clickedLocation={clickedLocation}
                            onMapPopupClose={onMapPopupClose}
                            linkedProfiles={linkedProfiles}
                        />
                    )}
                </>
            ) : isHovered ? (
                <MapMarkerHovered size="2x" />
            ) : (
                <MapMarkerDefault size="2x" />
            )}
        </div>
    );
};

export default LocationMapMarker;

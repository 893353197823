import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    buttonClassName,
    switchClassName,
    boxClassName,
    captionClassName,
    searchBarClassName,
} from '@archipro-design/aria';
import { OverrideGoogleMap } from '@modules/professional/component/contact-tab/OverrideGoogleMap.style';

export const MAP_WIDTH = 414;
export const MAP_HEIGHT = 700;
export const PIN_WIDTH = 40;
export const PIN_HEIGHT = 40;
export const POPUP_WIDTH = 335;
export const POPUP_HEIGHT = 288;

interface LocationListItemProps {
    isClicked?: boolean;
    isHovered?: boolean;
}

export const ContactContainer: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    fontSize: pxToRem(24),
    margin: pxArrayToRem([0, 18, 0, 18]),
});

export const ContentWrapper: StyleRule = () => ({
    width: '100%',
    padding: pxArrayToRem([0, 18]),
});

export const SearchBarSection: StyleRule = ({ isEmptyKeyword }) => ({
    [`& .${searchBarClassName}`]: {
        height: pxToRem(48),

        ':first-child': {
            padding: pxArrayToRem([0, 0, 0, 8]),
        },

        [`& .${buttonClassName}:first-child`]: {
            padding: 0,
        },
        [`& .${buttonClassName}:last-child`]: {
            display: isEmptyKeyword ? 'none' : 'block',
        },

        [`& input`]: {
            fontSize: pxToRem(20),
        },
    },
});

export const LocationListSection: StyleRule = () => ({});

export const LocationListWrapper: StyleRule = () => ({});

export const MapSection: StyleRule = ({ theme, isMapDisplayed }) => ({
    width: pxToRem(MAP_WIDTH),
    height: pxToRem(MAP_HEIGHT),
    margin: pxArrayToRem([0, 0, 80, -18]),
    '& > div:latest-child': {
        width: pxToRem(MAP_WIDTH),
        height: pxToRem(MAP_HEIGHT),
    },

    // no result text
    [`& .${captionClassName}`]: {
        padding: 0,
        margin: pxArrayToRem([0, 18, 16, 18]),
        lineHeight: pxToRem(24),
    },

    ...OverrideGoogleMap(theme, true),

    [`& .${boxClassName}`]: {
        display: isMapDisplayed ? 'block' : 'none',
    },
});

export const MapMarkerIconDiv: StyleRule<LocationListItemProps> = ({
    theme,
    isClicked,
    isHovered,
}) => {
    return {
        position: 'relative',
        top: pxToRem(-PIN_HEIGHT), // make the marker bottom-center point to the coordinate
        left: pxToRem(-(PIN_WIDTH / 2)),
        height: pxToRem(PIN_HEIGHT),
        width: pxToRem(PIN_WIDTH),
        cursor: 'pointer',

        '> span': {
            height: pxToRem(PIN_HEIGHT),
            width: pxToRem(PIN_WIDTH),
        },

        // when location or map-pin is clicked or hovered, change the map-pin z-index
        zIndex: isClicked
            ? theme.siteVariables.zIndexes.overlay
            : isHovered
            ? theme.siteVariables.zIndexes.foreground
            : theme.siteVariables.zIndexes.background,
    };
};

export const ListMapNavBox: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Roman55,
    fontSize: pxToRem(14),
    [`& .${switchClassName}`]: {
        '& > span': {
            marginLeft: 0,
        },
    },
});

export const LoadMoreButton: StyleRule = () => ({
    marginTop: pxToRem(40),
    width: '100%',
});

export const GetInTouchWrapper: StyleRule = () => ({
    marginTop: pxToRem(16),
});

export const ListMapSearchWrapper: StyleRule = () => ({
    padding: pxArrayToRem([17, 7, 10, 18]),
});

import type { BaseThemePrepared } from '@archipro-design/aria';
import { deviceWidths, pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { MAP_WIDTH } from '@modules/professional/component/contact-tab/ContactPage.mobile.style';

// NOTE: this style.ts file is only for override the inline-style generated by Google Map
// have to use !important to override the inline styles generated by Google

const AddLineIconImgString = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z'/%3E%3C/svg%3E`;
const SubtractLineIconImgString = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 11V13H19V11H5Z'/%3E%3C/svg%3E`;

export const OverrideGoogleMap = (
    theme: BaseThemePrepared,
    isMobile = false
) => {
    return {
        // Hide Google map links
        '& .gm-style .gm-style-cc': {
            display: 'none !important',
        },
        // Hide Google map logo
        '& .gm-style a[href^="https://maps.google.com/maps"]': {
            display: 'none !important',
        },

        // Google map zoom in/out buttons
        ...ZoomButtons(theme, isMobile),

        // Google map prompt message on scroll
        '& .gm-style-moc': {
            backdropFilter: 'blur(2px)',
            '& p.gm-style-mot': {
                fontSize: '24rem',
                lineHeight: '125%',
                paddingLeft: '8rem',
            },
        },
    };
};

const ZoomButtons = (theme: BaseThemePrepared, isMobile = false) => {
    const ZOOM_BUTTON_WIDTH = isMobile ? 40 : 56;
    const ZOOM_BORDER_NUMBER = isMobile ? 1 : 1;
    const ZOOM_ICON_SIZE = isMobile ? 24 : 30;
    const ZOOM_DIVIDER_HEIGHT = 1;
    const ZOOM_MARGIN = isMobile
        ? pxArrayToRem([0, 0, 0, -1])
        : pxArrayToRem([28, 0, 0, -60]);

    return {
        // Google map zoom in/out buttons
        '& .gm-bundled-control': {
            ...(!isMobile
                ? {
                      right: `${pxToRem(24)} !important`,
                      top: 'unset !important',
                  }
                : {}),
            bottom: `${pxToRem(169)} !important`,
        },
        '& .gm-bundled-control div.gmnoprint': {
            margin: ZOOM_MARGIN,
            width: `calc(${pxToRem(ZOOM_BUTTON_WIDTH)}) + ${
                ZOOM_BORDER_NUMBER * 2
            }px)`,
            height: `calc(${pxToRem(ZOOM_BUTTON_WIDTH * 2)}) + ${
                ZOOM_BORDER_NUMBER * 2 + ZOOM_DIVIDER_HEIGHT
            }px)`,

            border: `${ZOOM_BORDER_NUMBER}px solid ${theme.siteVariables.colors.charcoal[250]}`,

            '& div:first-child': {
                width: `${pxToRem(ZOOM_BUTTON_WIDTH)} !important`,
                height: `calc(${pxToRem(
                    ZOOM_BUTTON_WIDTH * 2
                )} + ${ZOOM_BORDER_NUMBER}px) !important`,
            },
            '& button': {
                width: `${pxToRem(ZOOM_BUTTON_WIDTH)} !important`,
                height: `${pxToRem(ZOOM_BUTTON_WIDTH)} !important`,
                '&:first-child': {
                    background: `#FFF url("${AddLineIconImgString}") no-repeat center / ${pxToRem(
                        ZOOM_ICON_SIZE
                    )} !important`,
                },
                '&:last-child': {
                    background: `#FFF url("${SubtractLineIconImgString}") no-repeat center / ${pxToRem(
                        ZOOM_ICON_SIZE
                    )}  !important`,
                },
                '& img': {
                    display: 'none',
                },
            },
            // divider line between zoom in/out buttons
            '& div:nth-child(2)': {
                width: `${pxToRem(ZOOM_BUTTON_WIDTH + 1)} !important`,
                minHeight: `${ZOOM_DIVIDER_HEIGHT}px`,
                margin: '0 !important',
                backgroundColor: `${theme.siteVariables.colors.charcoal[250]} !important`,
            },
        },

        //  adjust zoom-in/out button position for big mobile screen(tablet portrait)
        [`@media (min-width: ${MAP_WIDTH + 1}px) and (max-width: ${
            deviceWidths.lg
        })`]: {
            '& .gm-bundled-control div.gmnoprint': {
                marginLeft: `calc((100vw - ${MAP_WIDTH}px)/-10)`,
                width: `calc(${pxToRem(ZOOM_BUTTON_WIDTH)} + ${
                    ZOOM_BORDER_NUMBER * 2
                }px)`,
            },
        },
    };
};

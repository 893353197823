import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    buttonClassName,
    labelClassName,
    pxArrayToRem,
} from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import {
    POPUP_WIDTH,
    POPUP_HEIGHT,
    PIN_HEIGHT,
    PIN_WIDTH,
} from '@modules/professional/component/contact-tab/ContactPage.mobile.style';

export const MapPopupWrapper: StyleRule = ({
    theme,
    hasOnlineOrder = false,
}) => {
    const dynamicHeight = POPUP_HEIGHT - (hasOnlineOrder ? 0 : 48);
    return {
        padding: pxToRem(22),
        width: pxToRem(POPUP_WIDTH),
        position: 'relative',
        bottom: pxToRem(dynamicHeight + PIN_HEIGHT + 4),
        left: pxToRem(-(POPUP_WIDTH - PIN_WIDTH) / 2),
        background: theme.siteVariables.colors.white['100A'],
        color: theme.siteVariables.colors.charcoal[250],
        zIndex: theme.siteVariables.zIndexes.overlay,
        gridTemplateRows: 'repeat(4,auto) 1fr',
        ...theme.siteVariables.textStyles.Text.Roman55,
    };
};

export const LocationType: StyleRule = ({ theme }) => ({
    height: pxToRem(18),
    marginBottom: pxToRem(4),
    // location type text
    '& > div:first-child': {
        flexGrow: 1,
        fontSize: pxToRem(12),
        lineHeight: '120%',
        color: theme.siteVariables.colors.charcoal[100],
        ...theme.siteVariables.textStyles.Heading.Roman55,
        textTransform: 'uppercase',
    },

    // close button
    [`& .${buttonClassName}`]: {
        position: 'relative',
        lineHeight: 1,
        marginRight: pxToRem(-22),

        '& span': {
            marginTop: pxToRem(-8),
        },
    },
});

export const ClickAndCollectAvailable: StyleRule = () => ({
    [`& .${labelClassName}`]: {
        fontSize: pxToRem(14),
        lineHeight: 1.5,
    },
});

export const LocationTitle: StyleRule = ({ theme }) => ({
    [`& .${captionClassName}`]: {
        margin: pxArrayToRem([0, 0, 9, 0]),
        fontSize: pxToRem(16),
        lineHeight: 1.5,
        ...theme.siteVariables.textStyles.Heading.Roman55,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        textTransform: 'uppercase',
    },
});

export const LocationAddress: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(6),

    // address text
    [`& > .${captionClassName}`]: {
        height: pxToRem(18),
        margin: 0,
        fontSize: theme.siteVariables.fontSizes.caption04,
        lineHeight: 1,
        fontWeight: 500,
    },

    // clickToReveal text with ellipsis
    [`& .${labelClassName}`]: {
        fontSize: theme.siteVariables.fontSizes.caption04,
        lineHeight: 1,
        flexGrow: 1,
        paddingTop: pxToRem(3),
        letterSpacing: 0,
    },
});

export const LocationPhone: StyleRule = ({ theme }) => ({
    ...LocationAddress({ theme }),
});

export const LocationOrder: StyleRule = ({ theme }) => ({
    ...LocationAddress({ theme }),
    marginTop: pxToRem(2),
});

export const LocationActions: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([8, 8, -1, 0]),
    height: pxToRem(40),
    gap: pxToRem(8),
    '& div': {
        letterSpacing: 0,
    },

    // view profile button
    '& > div:first-child a': {
        margin: pxArrayToRem([12, 9, 0, 0]),
        textDecorationLine: 'underline',
    },

    // enquire button
    '& > div:nth-child(2)': {
        width: pxToRem(81),
        textAlign: 'right',
        [`& .${buttonClassName}`]: {
            padding: pxArrayToRem([16, 12, 16, 12]),
            height: pxToRem(40),
            background: theme.siteVariables.colors.white[300],
        },
    },
    // heart button
    '& > div:last-child': {
        width: pxToRem(30),
        marginTop: pxToRem(-3),
    },
});

export const EnquireButton: StyleRule = () => ({
    padding: pxArrayToRem([16, 25]),
    width: '100%',
    textTransform: 'uppercase',
    [`& .${labelClassName}`]: {
        fontSize: pxToRem(15),
        lineHeight: pxToRem(16.5),
    },
});

export const SaveToDesignBoardButton: StyleRule = () => ({
    border: `1.5px solid #222222`,
    width: pxToRem(48),
    height: '100%',
    '&:hover': {
        border: `1.5px solid #222222 !important`,
    },
    '&:active': {
        border: `1.5px solid #222222 !important`,
    },
});

export const EnquiryAndSaveWrapper: StyleRule = () => ({
    marginTop: pxToRem(20),
    height: pxToRem(48),
});

export const ViewProfileButton: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        fontSize: theme.siteVariables.fontSizes.caption04,
        lineHeight: 1,
        textDecoration: 'underline',
    },
});
